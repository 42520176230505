:root {
  --bg-primary: #1F262E;
  --bg-secondary: #E35A01;
  --bg-tertiary: #4C7C71;
}

.App {
  text-align: center;
  background-color: var(--bg-primary);
}

.App-header {
  background-color: var(--bg-primary);
  min-height: 25vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: top;
  font-size: calc(10px + 2vmin);
  font-family: 'Saira', sans-serif;
  color: white;
  padding: 20px;
}

.brand-text {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin + 25%);
  font-family: "Major Mono Display", monospace;
}

.App-body {
  background-color: var(--bg-primary);
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  font-family: 'Saira', sans-serif;
  padding: 18px;
}

.App-body-form {
  font-family: 'Saira', sans-serif;
  border-radius: 5px;
  padding: 3px;
  background-color: var(--bg-primary);
  margin: 0;
}

.App-body-form-button {
  color: white;
  padding: 10px;
  margin-top: 10px;
}

.App-body-form-button:hover {
  transition: 2s background ease;
  background-color: var(--bg-secondary);
}

.team-text {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  font-family: 'Saira', sans-serif;
  font-size: 15px;
  color: white;
}

.team-text-main {
  font-family: 'Saira', sans-serif;
  font-size: calc(12px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
  text-decoration: none;
  font-size: calc(10px + 2vmin);
}

.FlexBox {
  height:100px;
  border: solid 3px #040e21ba;
  background-color: var(--bg-primary);
}

.Welcome {
  --bg-size: 400%;
  --color-one: #4C7C71;
  --color-two: #1F262E;
  font-size: clamp(3rem, 25vmin, 8rem);
  background: linear-gradient(
                50deg,
                var(--color-one),
                var(--color-two),
                var(--color-one)
              ) 0 0 / 100% 100%;
  color: transparent;
  -webkit-background-clip: text;
  background-clip: text;
  padding: 0.1rem 0.2rem 0.1rem 1rem;
  font-family: 'Saira', sans-serif;
}

.Welcome-new {
  --bg-size: 400%;
  --color-one: #4C7C71;
  --color-two: #1F262E;
  font-size: clamp(2.25rem, 18.75vmin, 6rem);
  background: linear-gradient(
                50deg,
                var(--color-one),
                var(--color-two),
                var(--color-one)
              ) 0 0 / 100% 100%;
  color: transparent;
  -webkit-background-clip: text;
  background-clip: text;
  padding: 0.1rem 0.2rem 0.1rem 1rem;
  font-family: 'Saira', sans-serif;
}

.Welcome-new-main {
  --bg-size: 400%;
  --color-one: #4C7C71;
  --color-two: #E35A01;
  font-size: clamp(0.84375rem, 7.03125vmin, 2.25rem);
  background: linear-gradient(
                50deg,
                var(--color-one),
                var(--color-two),
                var(--color-one)
              ) 0 0 / 100% 100%;
  color: transparent;
  -webkit-background-clip: text;
  background-clip: text;
  padding: 0.05rem 0.1rem 0.05rem 0.5rem;
  margin: 0;
  line-height: 1.0;
  font-family: 'Saira', sans-serif;
}

.branding-logo {
  margin: auto;
  width: 250px;
  height: 52px;
  border-radius: 80px;
  background-origin: border-box;
  background-clip: padding-box, border-box;
}

.center-nav {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
  margin: 5px auto -28px auto;
  padding: 10px;
  max-width: 80%;
  height: 30px;
  font-size: 13px;
}

.nav-button {
  padding: 12px 15px;
  background-color: var(--bg-primary);
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-weight: 200;
  text-decoration: none;
  transition: background-color 0.3s;
}

.nav-button:hover {
  background-color: #E35A01;
}

.Bottom {
  display: flex;
  background-color: var(--bg-primary);
  align-items: center;
  justify-content: center;
  padding-bottom: 1%;
  color: white;
  min-height: 8vh;
  font-size: 15px;
  font-family: 'Saira', sans-serif;
}

.branding-logo {
  max-width: 100%;
  height: auto;
  display: block;
  margin: 0 auto;
}

.product-container {
  font-family: 'Saira', sans-serif;
  font-size: 14px;
  color: white;
  display: flex;
  flex-direction: row;
  width: 100%;
  margin-top: 20px;
}

.sidebar {
  width: 20%;
  border-right: 1px solid #ccc;
  padding: 15px;
}

.category {
  padding: 10px;
  margin: 5px 0;
  cursor: pointer;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.category:hover {
  background-color: #0f1e3b;
}

.category.active {
  background-color: #0f1e3b;
  font-weight: bold;
}

.product-display {
  width: 80%;
  padding: 10px;
}

.product-box {
  border: 1px solid #ccc;
  padding: 10px; 
  margin: 10px 0;
}

.collection-item {
  color: #E9EAEC;
}

.collection-defs {
  color: #E9EAEC;
}

/* Cookie Disclosure Styles */
.cookie-disclosure {
  position: fixed;
  bottom: 20px;
  left: 20px;
  background-color: var(--bg-tertiary);
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  padding: 15px 20px;
  z-index: 1000;
  max-width: 400px;
}

.cookie-disclosure-content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
  font-family: 'Saira', sans-serif;
  color: white;
}

.cookie-disclosure-content p {
  margin: 0;
  font-size: 12px;
}

.cookie-disclosure-buttons {
  display: flex;
  align-items: center;
  gap: 10px;
}

.got-it-btn {
  background-color: var(--bg-secondary);
  color: white;
  border: none;
  padding: 8px 12px;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
  font-family: 'Saira', sans-serif;
  white-space: nowrap;
  min-width: 60px;
}

.got-it-btn:hover {
  background-color: var(--bg-secondary);
}

.close-btn {
  background: none;
  border: none;
  color: #666;
  cursor: pointer;
  font-size: 16px;
  padding: 4px 8px;
}

.close-btn:hover {
  color: #333;
}
